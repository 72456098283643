import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Avatar from './avatar';

import './layout.css';
import '../../static/style.css';

const Layout = ({ mode, children }) => {
  return (
    <div className={`layout-wrapper ${mode}`}>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Domine:wght@500&display=swap" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" /> */}
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU" crossorigin="anonymous" />
      </Helmet>
      <div className="layout">
        <div className="page">
          <div className="page-content">
            {children}
          </div>                              
          <Avatar />
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
