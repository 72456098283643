import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title, className }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            bodyAttributes={{
              class: className
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
            <script type="application/ld+json">
                {
                    `{
                        "@context": "http://schema.org",
                        "@type": "Website",
                        "url": "https://chalamphong.com"
                    }`
                }
            </script>
            <script>
              {`var firebaseConfig = { 
                apiKey: "AIzaSyCrBCRFky-3n_mULuqs0hn3nBi3_Dj1dJc",
                authDomain: "portfolio-chalamphong.firebaseapp.com",
                databaseURL: "https://portfolio-chalamphong.firebaseio.com",
                projectId: "portfolio-chalamphong",
                storageBucket: "portfolio-chalamphong.appspot.com",
                messagingSenderId: "569565216219",
                appId: "1:569565216219:web:5d603603872a37a3b77bfe"
              };
              firebase.initializeApp(firebaseConfig);`}
            </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
