import React from 'react';

import AvatarSVG from '../assets/avatar.svg';
import './avatar.css';

const Avatar = () => (
  <div className="avatar-wrapper">
    <AvatarSVG className="avatar" />
  </div>  
);

export default Avatar;
